html {
  scroll-behavior: smooth;
}

body {
  background-color: #0d1119;
  color: #fff;
}

.btn {
  transition-property: background-color;
  transition-duration: 0.6s;
  font-weight: 500;
  padding: 0.25rem 1.5rem;
  border-radius: 0.25rem;
}

input {
  color: black;
}

@media screen and (min-width: 1024px) {
  .small-container {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
}

@media screen and (max-width : 800px){
  .hidden{
    display: none;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
